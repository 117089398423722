<template>
	<div>
		<div>
			<div class="input-section" v-if="buyInOfferSelected">
				<label for="fromPlayerCashCOC">{{ languageStrings.cashReceived }}</label>
				<div id="fromPlayerCashCOC">
					<span>
						<span v-if="systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
							systemCurrencyTool.currencyInfo.symbol
						}}</span>
						<input
							type="number"
							name="displayCOC"
							v-model="fromPlayerCashCOC"
							:placeholder="1"
							autocomplete="off"
							id="currency"
							@blur="updateFromPlayerCashCoC()"
						/>
						<span v-if="!systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
							systemCurrencyTool.currencyInfo.symbol
						}}</span>
					</span>
					<span>
						<span class="currancy-symbol">{{ systemCurrencyTool.currencyInfo.symbol }}</span>
						<input
							type="range"
							name="displayCOC"
							:min="buyInOffer.minimumCOC"
							:max="buyInOffer.maximumCOC"
							v-model="fromPlayerCashCOC"
							autocomplete="off"
						/>
						<span class="currancy-symbol"
							>{{ systemCurrencyTool.currencyInfo.symbol }}{{ systemCurrencyTool.currencyInfo.symbol
							}}{{ systemCurrencyTool.currencyInfo.symbol }}</span
						>
					</span>
				</div>
				<div>
					<div class="row">
						<div class="column">
							<button class="btn add-in-btn encore-price-button" @click="addMoneyToInput(10)">
								<span v-if="systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
									systemCurrencyTool.currencyInfo.symbol
								}}</span>
								10
								<span v-if="!systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
									systemCurrencyTool.currencyInfo.symbol
								}}</span>
							</button>
						</div>
						<div class="column">
							<button class="btn add-in-btn encore-price-button" @click="addMoneyToInput(25)">
								<span v-if="systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
									systemCurrencyTool.currencyInfo.symbol
								}}</span>
								25
								<span v-if="!systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
									systemCurrencyTool.currencyInfo.symbol
								}}</span>
							</button>
						</div>
					</div>
					<div class="row">
						<div class="column">
							<button class="btn add-in-btn encore-price-button" @click="addMoneyToInput(50)">
								<span v-if="systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
									systemCurrencyTool.currencyInfo.symbol
								}}</span>
								50
								<span v-if="!systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
									systemCurrencyTool.currencyInfo.symbol
								}}</span>
							</button>
						</div>
						<div class="column">
							<button class="btn add-in-btn encore-price-button" @click="addMoneyToInput(100)">
								<span v-if="systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
									systemCurrencyTool.currencyInfo.symbol
								}}</span>
								100
								<span v-if="!systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
									systemCurrencyTool.currencyInfo.symbol
								}}</span>
							</button>
						</div>
					</div>
				</div>
				<div class="button-container">
					<button class="btn buy-in-btn curved-border" :id="`commit-offer${buyInOffer.id}`" @click="order()">
						{{ languageStrings.commitTransaction }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
import { onBeforeUnmount } from "vue";
import router from "@/router";

export default {
	name: "CommitBuyInOffer",
	props: {
		buyInOfferSelected: Boolean,
		playerState: Object,
		buyInOffer: Object,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
		webPayOptions: Object,
		isMobile: Boolean,
	},
	data() {
		return {
			activeSession: this.session.get(),
			status: Object.assign({}, this.globalStatus),
			// inputConfig: Object.assign({}, this.globalInputConfig),
			currencyInfo: this.playerState.currencyInfo,
			systemCurrencyInfo: this.systemCurrencyTool.currencyInfo,
			fromPlayerCashCOC: parseFloat(this.buyInOffer.minimumCOC).toFixed(2),
			displayCOC: 0,
			regularAU: 0,
			buyIn: {
				cashierBankId: 0,
				playerUserId: "",
				accountAccessCode: 0,
				buyInOfferId: 0,
				fromPlayerCashCOC: 0,
			},
			transactionReport: {},
			buyInTransactionDetails: {},
			currencyInfo: this.playerState.currencyInfo,
			systemCurrencyInfo: this.systemCurrencyTool.currencyInfo,
			systemMinorOrFull: this.systemCurrencyTool.displayType.minorOrFull,
			systemFull: this.systemCurrencyTool.displayType.full,
			serverBusy: false,
			busyText: "",
			transaction: {},
			nuveiResult: {},
			playerFunds: {},
			transactionResult: {},
		};
	},
	watch: {
		buyInOfferSelected() {
			if (!this.buyInOfferSelected) this.fromPlayerCashCOC = null;
		},
		fromPlayerCashCOC() {
			this.fromPlayerCashCOC = Number(this.fromPlayerCashCOC);
		},
	},
	created() {
		// onBeforeUnmount(() => {
		// 	checkout.destroy(async (response) => {
		// 		console.log(await response);
		// 	});
		// });
	},
	methods: {
		addMoneyToInput(amount) {
			let fromPlayer = Number(this.fromPlayerCashCOC) + amount;
			if (fromPlayer > this.buyInOffer.maximumCOC) return;
			this.fromPlayerCashCOC = parseFloat(fromPlayer).toFixed(2);
		},
		async order() {
			this.serverBusy = true;
			this.busyText = this.languageStrings.initiatingBuyIn;

			let hasErrors = false;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			if (!this.fromPlayerCashCOC) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.mustEnterBuyInAmount;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			if (isNaN(this.fromPlayerCashCOC)) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.mustEnterNumberOnly;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			if (this.fromPlayerCashCOC < this.buyInOffer.minimumCOC) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.mustMeetMinimumBuyIn;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			if (this.fromPlayerCashCOC > this.buyInOffer.maximumCOC) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.cannotExceedMaximumBuyIn;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			if (
				this.fromPlayerCashCOC > this.webPayOptions.maxWebBuyInWithoutIdCheckCOC &&
				!this.webPayOptions.isIdCheckVerified
			) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = `${this.systemCurrencyTool.formatCurrency(
					this.webPayOptions.maxWebBuyInWithoutIdCheckCOC,
					this.systemCurrencyTool.displayType.full
				)} ${
					this.languageErrorStrings
						.isTheMaxThatCanBeDepositedWithoutAnIdCheckPleaseCompleteANeoCheckIdVerification
				}.`;
				this.eventBus.emit("updateStatus", this.status);
				router.push("/identificationStatus");
				return false;
			}

			if (hasErrors === true) return;

			let body = {
				CasinoId: this.webPayOptions.casino.id,
				BuyInOfferId: this.buyInOffer.id,
				FromPlayerCashCOC: this.fromPlayerCashCOC,
				provider: "Nuvei",
			};

			let requestUrl = new URL("/api/v1/webpay/buyin/initiate", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				this.transaction = await response.json();

				if (this.transaction?.status === "EmailNotVerified") {
					console.error(this.transaction?.status);
					this.status.ok = false;
					this.status.message =
						this.languageErrorStrings.emailNotVerifiedPleaseGoToYourUserProfileAndVerifyYourEmail;
					this.eventBus.emit("updateStatus", this.status);
					return;
				}

				if (fetchStatus.ok)
					this.status = sharedScripts.checkSuccessErrors(this.transaction.status, this.languageErrorStrings);

				if (this.status.message === "Success") {
					this.status.ok = true;
					this.status.message = this.languageErrorStrings.webPayInitiated;
					this.eventBus.emit("updateStatus", this.status);
				}

				this.performNuveiCheckout();
			} catch (e) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.webPayInitiateFailed;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
			}
		},
		// Dummy function, but you get the idea.  Obviously we need something more robust than this.
		getTwoLetterCountry(phoneCountryCode) {
			switch (phoneCountryCode) {
				case 1:
					return "US";
				case 52:
					return "MX";
				default:
					return "US";
			}
		},
		performNuveiCheckout() {
			this.serverBusy = true;
			this.busyText = this.languageStrings.displayNuveiPurchaseWidget;

			/*
        Log levels for Nuvei's Simply Connect "checkout" API.
        Possible values:
            0 (default) – No logging
            1 – Plain text
            2 – Info
            3 – Warn
            4 – Error
            5 – Debug
            6 – Trace
     */
			const nuveiLogLevel = 0;

			// About countries and payment methods:
			// The top-level country field is where we, the merchant, do business.  For Encore this will be MX, for instance.
			// The billingAddress.country is the player/user/consumer's country, and we base that on their phone number.
			// According to Andres Mauricio Clavijo Mantilla in our Teams chat on 2024-01-19, only credit cards are allowed for
			// foreigners to the business country, not "alternate payment methods" such as PayPal or OXXOPay.
			// The API fails quite severely if you try.  So we're using the "payment method whitelist" to make sure that
			// APMs aren't offered if the countries don't match.  (If no whitelist or blacklist are given, Nuvei shows all
			// methods we're set up to use.)
			const billingAddrCountry = this.getTwoLetterCountry(this.webPayOptions.userPhoneCountryCode);
			const payMethodWhitelist =
				billingAddrCountry === this.webPayOptions.nuvei.merchantTwoLetterCountryCode ? null : ["cc_card"];

			// Hard-coding the locale for this prototype.  In production, we should use whatever mechanism we're using for
			// front-end language elsewhere.
			let rfServer;
			let statusArea;
			let rfWebTransaction;
			let sessionId;
			let alreadyShowingResults;

			// if (document.getElementById("nuvei-checkout-element").innerHTML !== "") {
			// 	checkout.destroy();
			// }

			// Tell Nuvei to create their UI inside one of our DOM elements.
			document.getElementById("nuvei-checkout-element").innerHTML = "";
			const checkoutParams = {
				sessionToken: this.transaction.sessionId,
				env: this.webPayOptions.nuvei.environment,
				merchantSiteId: this.webPayOptions.nuvei.merchantSiteId,
				merchantId: this.webPayOptions.nuvei.merchantId,
				country: this.webPayOptions.nuvei.merchantTwoLetterCountryCode,
				currency: this.webPayOptions.cashOutCurrency.iso4217Code,
				locale: this.systemCurrencyTool.cultureCode,
				userId: this.transaction.transaction.userId,
				amount: this.transaction.transaction.fromPlayerCashCOC,
				pmWhitelist: payMethodWhitelist,
				renderTo: "#nuvei-checkout-element",
				logLevel: nuveiLogLevel,
				billingAddress: {
					country: billingAddrCountry,
					email: this.webPayOptions.userEmailAddress,
					phone: this.webPayOptions.userPhoneNumber,
				},
				showResponseMessage: false,
				onResult: this.handleNuveiCheckoutResult,
				onPaymentEvent: this.handleNuveiPaymentEvent,
			};

			checkout(checkoutParams);
			this.eventBus.emit("showNuveiWidgit");
		},
		async handleNuveiCheckoutResult(result) {
			let response = await result;

			if (result?.result === "ERROR" && !result?.statusAdditionalInfo) {
				this.fromPlayerCashCOC = null;
				checkout.destroy();
				this.eventBus.emit("handelNuveiError", result);
				return;
			}

			if (response?.result) {
				this.nuveiResult = response;
				this.fromPlayerCashCOC = null;
				checkout.destroy();
				setTimeout(() => {
					this.showRfTranStatus();
				}, 1500);
			}
		},
		async handleNuveiPaymentEvent(result) {
			let response = await result;

			if (response?.result) {
				this.fromPlayerCashCOC = null;
				checkout.destroy();
				this.eventBus.emit("nuveiChecoutResult", this.nuveiResult);
			}
		},
		async showRfTranStatus() {
			this.busyText = this.languageStrings.loadingBuyIns;
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL(
				`api/v1/webpay/transaction/${this.transaction.transaction.id}`,
				this.rabbitsfootHostUrl
			);
			let params = requestUrl.searchParams;

			request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();
				this.transactionResult = dataJson;

				if (this.transactionResult?.status === "RefusedByProvider") {
					this.fromPlayerCashCOC = null;
					checkout.destroy();
					this.eventBus.emit("handelNuveiError", this.transactionResult);
					return;
				}

				this.eventBus.emit("nuveiChecoutResult", this.transactionResult);

				this.busyText = "";
				this.serverBusy = false;
			} catch (e) {
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}

			headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			requestUrl = new URL("api/v1/funds/", this.rabbitsfootHostUrl);
			params = requestUrl.searchParams;

			if (this.transaction.transaction.casinoId) params.set("casinoId", this.transaction.transaction.casinoId);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);
				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.playerFunds = dataJson;
			} catch (e) {
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}
		},
		updateFromPlayerCashCoC() {
			this.fromPlayerCashCOC = parseFloat(this.fromPlayerCashCOC).toFixed(2);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input-section {
	width: 100%;
	align-items: center;
}

.input-section span {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.input-section input {
	max-width: 90%;
	border-radius: 22px;
	text-align: center;
	font-size: 29px;
	color: #6305c9;
}

.buy-in-btn {
	background: linear-gradient(to right, blue, purple, #fa5f55);
	-webkit-text-fill-color: transparent;
	background-clip: text;
	margin: 5px auto;
	width: 22vw;
	height: 4.5vh;
	font-weight: bolder;
	overflow-wrap: break-word;
}

button {
	display: block;
}

.column {
	flex: 1;
}

.row {
	display: flex;
}

.add-in-btn {
	display: flex;
	opacity: 0.8;
	background-image: none;
}

div#fromPlayerCashCOC {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.currancy-symbol {
	padding: 0 5px;
	font-size: large;
}

/* #fromPlayerCashCOC input {
    width: 10em;
} */

#fromPlayerCashCOC span span {
	justify-content: end;
	width: 2em;
}

input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	cursor: pointer;
	background: #5d3fd3;
	border-radius: 50%;
	height: 20px;
	width: 20px;
}

input[type="range"]::-moz-range-thumb {
	background: #5d3fd3;
	height: 15px;
	width: 15px;
}

.button-container {
	background: white;
	border-radius: 15px;
	width: 22vw;
	margin-left: auto;
	margin-right: auto;
}

.button-container:hover {
	width: 23vw;
}

@media (max-width: 768px) {
	.input-section input {
		height: 45px;
		text-align: center;
		font-size: 35px;
		color: #46289b;
		max-width: 40vw;
	}
	.button-container {
		background: white;
		border-radius: 15px;
		width: 22vw;
		margin-left: 13vw;
	}

	.button-container:hover {
		width: 23vw;
	}
}
</style>
